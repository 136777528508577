<template>
  <div class="parkPackageManageForm-wrapper">
    <form-panel
      ref="formPanel"
      :form="form"
      submitText="保存"
      v-bind="submitConfig"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block title="车位信息">
        <el-form-item
            label="套餐名称"
            prop="suitName"
            :rules="[
              { required: true, message: '请输入套餐名称', trigger: 'blur' }
            ]"
          >
            <div class="content-wrapper">
              <v-input
                v-model="form.suitName"
                placeholder="请输入套餐名称"
              ></v-input>
            </div>
          </el-form-item>
          <el-form-item
            label="车位类型"
            prop="rentType"
            :rules="[ { required: true, message: '请选择车位类型', trigger: 'change' } ]"
          >
            <v-select v-model="form.rentType" :options="rentTypeOps" :disabled="!isAdd" @change="rentTypeChange"></v-select>
          </el-form-item>
          <!-- <el-form-item
            v-if="isRentType"
            label="可出租车位"
            prop="roomNumIdList"
            :rules="[ { type: 'array', required: true,  message: '当前选项不允许为空', trigger: 'change' }  ]"
          >
            <chosenListPanel :list.sync="form.roomNumIdList" @select="toSelectCarport"></chosenListPanel>
          </el-form-item>
          <el-form-item
            v-if="!isRentType"
            label="可出租车位数"
            prop="rentType"
            :rules="[ { required: true, message: '当前选项不允许为空', trigger: 'change' } ]"
          >
            <v-input-number v-model="parkingNumber" :controls="true" />
          </el-form-item> -->
          <el-form-item
            label="选择项目"
            prop="communityId"
            :rules="[ { required: true,  message: '请选择项目', trigger: 'change' } ]"
          >
            <v-select2
              v-model="form.communityId"
              placeholder="查询项目"
              v-bind="projectParams"
              :disabled="!isAdd"
              @onChange="projectChange"
            ></v-select2>
          </el-form-item>
          <el-form-item
            label="选择停车场"
            prop="parkingId"
            :rules="[ { required: true, message: '请选择停车场', trigger: 'change' } ]"
          > 
            <v-select2
              v-model="form.parkingId"
              placeholder="选择停车场"
              v-bind="parkParams"
              :disabled="!form.communityId||!isAdd"
              @onChange="parkChange"
              :subjoin="communitySubjoin"
            ></v-select2>
          </el-form-item>
          <el-form-item
            v-if="form.parkingId && form.rentType==1"
            label="选择车位"
            prop="roomNumIdList"
            :rules="[ { type: 'array', required: true,  message: '请选择车位', trigger: 'change' }  ]"
          >
            <chosenListPanel :list.sync="form.roomNumIdList" @select="toSelectCarport"></chosenListPanel>
          </el-form-item>
          <!-- <el-form-item
            v-if="form.parkingId && form.rentType==2"
            label="可出租车位数"
            prop="virtualCount"
            :rules="[ { required: true, message: '当前选项不允许为空', trigger: 'change' } ]"
          >
            <v-input-number v-model="form.virtualCount" :controls="true" />
          </el-form-item>  -->
          <el-form-item
            label="套餐类型"
            v-show="!isPersonal"
            :rules="!isPersonal ? [ { required: true, message: '当前选项不允许为空', trigger: 'change' } ]: null"
          >
            <checkbox-plus type="radio" :options="suitTypeOps" :value.sync="form.suitType" :disabled="!isAdd"></checkbox-plus>
          </el-form-item>
          <el-form-item
            label="套餐价格"
            v-show="!isPersonal"
          >
            <div class="content-wrapper">
              <div class="suit-price-tip">{{totalMoney}}元<span>*套餐价格=车位管理费+车位租赁费</span></div>
              <div class="suit-price-manage">
                <span>车位管理费</span>
                <!-- <v-input-number
                v-model="form.administrationPrice"
                placeholder="请输入套餐价格"
                :min="0"
                :max="99999"
                ></v-input-number> -->
                <v-select2
                  v-model="form.administrationChargeSubjectId"
                  v-bind="chargeSubjectParams"
                  placeholder="按收费科目查询"
                  :width="width"
                ></v-select2>
                <el-form-item prop="administrationChargeRuleId" :rules="!isPersonal ? { required: true, message: '请选择标准收费规则', trigger: 'change'} : null">
                  <v-select2
                    v-model="form.administrationChargeRuleId"
                    v-bind="rulePublicParams"
                    :subjoin="extraRuleParams"
                    placeholder="选择标准收费规则"
                    :width="width"
                    :disabled="!form.communityId||!form.administrationChargeSubjectId"
                    @onChange="rulePublicChange"
                  ></v-select2>
                </el-form-item>
              </div>
              <div class="suit-price-manage">
                <span>车位租赁费</span>               
                  <!-- <v-input-number
                  v-model="form.leasePrice"
                  placeholder="请输入套餐价格"
                  :min="0"
                  :max="99999"
                  :disabled="form.rentType==3"
                ></v-input-number> -->
                <v-select2
                  v-model="form.leaseChargeSubjectId"
                  v-bind="chargeSubjectParams"
                  placeholder="按收费科目查询"
                  :width="width"
                ></v-select2>
                <el-form-item prop="leaseChargeRuleId" :rules="!isPersonal ? { required: true, message: '请选择标准收费规则', trigger: 'change'} : null">
                <v-select2
                  v-model="form.leaseChargeRuleId"
                  v-bind="rulePublicParams"
                  :subjoin="extraRuleParams1"
                  placeholder="选择标准收费规则"
                  :width="width"
                  :disabled="!form.communityId||!form.leaseChargeSubjectId"
                  @onChange="rulePublicChange1"
                ></v-select2>
                </el-form-item>
              </div>
              <div class="reFund-wrapper">
                <v-checkbox v-model="isChecked" @change="handleCheckboxChange" label="可退费" class="checkbox-ref"></v-checkbox>
                <checkbox-plus type="radio" :disabled="isReFund" :options="vCheckRadiooptions" :value.sync="form.isReturnPremium" />
              </div>
            </div>
          </el-form-item>
          <el-form-item
            label="开放对象"
            :rules="[ { required: true,  message: '当前选项不允许为空', trigger: 'change' } ]"
          >
            <checkbox-plus :disabled="!isAdd" type="radio" :options="orientTypeOps" :value.sync="form.orientType"></checkbox-plus>
          </el-form-item>
          <el-form-item
            label="是否需要审核"
            :rules="[ { required: true,  message: '当前选项不允许为空', trigger: 'change' } ]"
          >
            <checkbox-plus :disabled="!isAdd" type="radio" :options="needCheckTypeOps" :value.sync="form.isAudit"></checkbox-plus>
          </el-form-item>
          <el-form-item
            label="停车协议"
            prop="protocol"
            v-show="!isPersonal"
            :rules="!isPersonal ? [{ required: true, message: '请输入停车协议', trigger: 'change' }] : null"
          >
            <v-ueditor v-model="form.protocol"></v-ueditor>
          </el-form-item>
          <el-form-item
            label="状态"
            prop="status"
            :rules="[ { required: true, message: '当前选项不允许为空',  trigger: 'change'  }  ]"
          >
            <v-select
              v-model="form.status"
              :options="statusOps"
            ></v-select>
          </el-form-item>
          <multi-select
            title="选择车位"
            :isShow.sync="isShow"
            :searchUrl="getParkSuitSlotURL"
            :headers="tableHeader"
            :searchParams="searchParams"
            :extraParams="extraParams"
            :responseParams="responseParams"
            :responseKey="responseKey"
            :backFill.sync="form.roomNumIdList">
            <template #searchSlot>
                <v-select label="入住状态" v-model="searchParams.checkInStatus" :options="checkInStatusOps"></v-select>
                <v-input label="分期" v-model="searchParams.blockName"></v-input>
                <v-input label="幢" v-model="searchParams.buildingNumber"></v-input>
                <v-input label="单元" v-model="searchParams.unit"></v-input>
                <v-input label="楼层" v-model="searchParams.floor"></v-input>
                <v-input label="室" v-model="searchParams.room"></v-input>
            </template>
         </multi-select>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import {
  getParkPackageDetailURL,
  getParkSuitSlotURL,
  saveParkPackageURL,
  getParkingByCommunityURL,
  chargeSubjectURl,
  chargeRulePublictURl
} from './api'
import {
  statusOps,
  rentTypeOps,
  checkInStatusOps,
  checkInStatusMap,
  houseTypeMap
} from './map'
import { Col2Block, Col2Detail, CheckboxPlus, MultiSelect, ChosenListPanel } from 'components/bussiness'
import { vCheckbox } from 'components/control'
import { vUeditor } from 'components/control/index'
import { normalCommunityParams } from 'common/select2Params'

export default {
  name: '',
  components: {
    Col2Block,
    Col2Detail,
    MultiSelect,
    ChosenListPanel,
    CheckboxPlus,
    vUeditor,
    vCheckbox
  },
  data () {
    return {
      isPersonal: false, // 是否选择私家车位
      isChecked: this.isReturnPremium != 0 ? true : false, 
      checkradioValue: 1,
      isReFund: false,
      vCheckoptions: [{
        label: '可退费',
        value: 1
      }],
      vCheckRadiooptions: [
        {
         label: '按月退',
         value: 1,
         disabled: false
        }, {
         label: '按日退',
         value: 2,
         disabled: false
        }
      ],
      isRentType: true,
      width: 182,
      editFlag: true,
      editFlag1: true,
      submitConfig: {},
      getParkSuitSlotURL: getParkSuitSlotURL,
      // 去除全部
      rentTypeOps: rentTypeOps.slice(1),
      statusOps: statusOps.slice(1),
      checkInStatusOps: checkInStatusOps,
      projectParams: normalCommunityParams,
      form: {
        roomNumIdList: [],
        isReturnPremium: 1, // 是否可退费
        // communityId: '',
        protocol: '',
        // zoneName: '',
        orientType: 1,
        status: 1,
        // rentType: 1,
        suitType: 1, // 套餐类型
        suitName: '',
        communityId: '',
        administrationPrice: 0, // 管理费
        leasePrice: 0, // 租赁费
        parkingId: '',
        administrationChargeSubjectId: '',
        administrationChargeRuleId: '',
        leaseChargeSubjectId: '',
        leaseChargeRuleId: '',
        virtualCount: 0,
        isAudit: '0'
      },
      searchParams: {
        checkInStatus: undefined,
        blockName: '',
        buildingNumber: '',
        unit: '',
        floor: '',
        room: ''
      },
      isAdd: false,
      suitTypeOps: [{
        label: '包月',
        value: 1
      }, {
        label: '包年',
        value: 2
      }],
      suitTypeOps1: [{
        label: '包月',
        value: 1
      }, {
        label: '包年',
        value: 2
      }],
      disabled: false,
      tableHeader: [
        {
          prop: 'houseTypeStr',
          label: '房屋类型',
          formatter (row) {
            return houseTypeMap[row.houseType]
          }
        },
        {
          prop: 'checkInStatusStr',
          label: '入住状态',
          formatter (row) {
            return checkInStatusMap[row.checkInStatus]
          }
        },
        {
          prop: 'blockName',
          label: '分期'
        },
        {
          prop: 'buildingNumber',
          label: '幢'
        },
        {
          prop: 'unit',
          label: '单元'
        },
        {
          prop: 'floor',
          label: '楼层'
        }, {
          prop: 'room',
          label: '室'
        }],
      isShow: false,
      responseParams: {
        id: 'roomNumId',
        name: 'roomNumName'
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      parkParams: {
        searchUrl: getParkingByCommunityURL,
        request: {
          text: 'parkingName',
          value: 'parkingId'
        },
        response: {
          text: 'name',
          value: 'id'
        }
      },
      chargeSubjectParams: {
        searchUrl: chargeSubjectURl,
        request: {
          text: 'subjectName',
          value: 'id'
        },
        response: {
          text: 'subjectName',
          value: 'id'
        }
      },
      rulePublicParams: {
        searchUrl: chargeRulePublictURl,
        request: {
          text: 'ruleName',
          value: 'rulePublicId'
        },
        response: {
          text: 'ruleName',
          value: 'rulePublicId'
        }
      }
    }
  },

  computed: {
    extraRuleParams () {
      return {
        chargeSubjectId: this.form.administrationChargeSubjectId,
        communityId: this.form.communityId
      }
    },
    extraRuleParams1 () {
      return {
        chargeSubjectId: this.form.leaseChargeSubjectId,
        communityId: this.form.communityId
      }
    },
    extraParams () {
      return {
        parkingId: this.form.parkingId,
        communityId: this.form.communityId,
        isLot: 1
        // 此处还要加停车场
      }
    },
    communitySubjoin () {
      return {
        communityId: this.form.communityId
      }
    },
    totalMoney () {
      if(this.form.administrationPrice&&this.form.leasePrice){
        return this.form.administrationPrice + this.form.leasePrice
      } else if(this.form.administrationPrice&&!this.form.leasePrice){
        return this.form.administrationPrice
      }else if(!this.form.administrationPrice&&this.form.leasePrice){
        return this.form.leasePrice
      }else {
        return 0
      }
    },
    orientTypeOps () {
      return [{
        label: '不限',
        value: 1,
      }, {
        label: '业主',
        value: 2,
      }, {
        label: '非业主',
        value: 3,
      }]
    },
    needCheckTypeOps () {
      return [{
        label: '需要审核',
        value: '1'
      }, {
        label: '不需要审核',
        value: '0'
      }]
    }
  },
  watch: {
    form(val) {
      // 解决 checkbox-plus 识别到 value = 0 自动转成false的问题。
      val.isAudit = String(val.isAudit)
    }
  },
  created () {
    // 默认新增
    let add = true
    if (this.$route.query && this.$route.query.id) {
      // 编辑put
      add = false
    }
    this.isAdd = add
    this.submitConfig = {
      queryUrl: `${getParkPackageDetailURL}/${this.$route.query.id}`,
      submitMethod: add ? 'post' : 'put',
      submitUrl: saveParkPackageURL
    }

    let title = add ? '新增' : '编辑'
    // 设置title
    this.$setBreadcrumb(title)
  },

  // 加载详情数据
  async mounted () {
    if (!this.isAdd) {
      // 获取详情
      await this.$refs.formPanel.getData()
    }
  },

  methods: {

    // 项目改变
    projectChange (value) {
        // 新增的时候
        let change = true
        if (value && this.form.communityId === value) {
        // 代表和之前选择的项目一致
          change = false
        }
        if (change) {
          if(!this.editFlag1) {
            this.form.parkingId = ''
            this.form.leaseChargeRuleId = ''
            this.form.administrationChargeRuleId = ''
          }
          this.editFlag1 = false
        }
    },
    // 停车场改变
    parkChange (value) {
      let change = true
        if (value && this.form.parkingId === value) {
        // 代表和之前选择的项目一致
          change = false
        }
        if (change) {
        // 如果有改变，之前选择的了车位，就要清除
          if (this.form.roomNumIdList && this.form.roomNumIdList.length > 0 && !this.editFlag) {
            this.form.roomNumIdList = []      
          }
          this.editFlag = false
        }
    },
    // 去选择车位
    toSelectCarport () {
      this.isShow = true
    },

    update (data) {
      let form = Object.assign({}, data)
      // 处理车位的回显
      let roomNumIdList = []
      if (form.slotVList) {
        form.slotVList.forEach(item => {
          roomNumIdList.push({
            id: item.roomNumId,
            text: item.roomNumName
          })
        })
      }
      form.roomNumIdList = roomNumIdList
      this.form = form
    },

    submitBefore (data) {
      if(!data.leasePrice){
        data.leasePrice = 0
      }
      let tempList = []
      data.roomNumIdList.forEach(item => {
        tempList.push(item.id)
      })
      data.roomNumIdList = tempList
      // 删除多余字段
      delete data.communityName
      delete data.slotVList
      data.isAudit = Number(data.isAudit) // isAudit 转成 Number 类型请求
      return true
    },
    // 车位类型改变
    rentTypeChange (data) {
      if(data==3){
        this.isPersonal = true
        // if(this.form.suitType== 4){
        //   this.form.suitType = undefined
        // }
        // this.suitTypeOps=this.suitTypeOps1.slice(0,2)
        // this.form.price1=0
        // this.disabled=true
      } else {
        this.isPersonal = false
        this.suitTypeOps=this.suitTypeOps1
        this.disabled=false
      }
    },
    rulePublicChange (val, first) {
      if (val && !first) {
        this.form.administrationChargeRuleId = val.rulePublicId
        this.form.administrationPrice = val.billingPrice
      } else if (!first) {      
        this.form.administrationChargeRuleId = ''
        this.form.administrationPrice = 0
        // this.$refs.legalUnitSelect2.clearValue()
      }
    },
    rulePublicChange1 (val, first) {
      if (val && !first) {
        this.form.leaseChargeRuleId = val.rulePublicId    
        this.form.leasePrice = val.billingPrice
      } else if (!first) {      
        this.form.leaseChargeRuleId = ''
        this.form.leasePrice = 0
        // this.$refs.legalUnitSelect2.clearValue()
      }
    },

    handleCheckboxChange (value, index) {
      if (value) {
        this.form.isReturnPremium = 1
        this.vCheckRadiooptions[0].disabled = false
        this.vCheckRadiooptions[1].disabled = false
      } else {
        this.vCheckRadiooptions[0].disabled = true
        this.vCheckRadiooptions[1].disabled = true
        this.form.isReturnPremium = 0
      }
      // this.isReFund = !this.isReFund
    }
  }
}
</script>

<style lang="scss" scoped>
.parkPackageManageForm-wrapper {
  ::v-deep .checkbox-plus-wrapper .check-item {
    margin-top: 0;
  }
  .content-wrapper {
    .suit-price-tip{
      span{
        color: red;
        padding-left: 20px;
      }
    }

    .suit-price-manage{
      padding-top: 20px;
      display: flex;
      >span{
        padding-right: 20px;
      }
    }
  
    .v-select2-container {
      display: inline-block;
      margin-right: 15px;
    }
    .reFund-wrapper {
      display: flex;
      align-items: center;
      .checkbox-ref {
        margin-right: 20px;
      }
    }
  }
}
</style>
